import React from "react";
import Footer from "../components/footer/Footer";
import img5 from "../img/img5.jpg";
import img5Webp from "../img/img5.webp";
import Header from "../components/header/Header";
import "./contact.scss";
import ImgWithFallback from "../components/layout/ImgWithFallback";

export default function Contact() {
    return (
        <>
            <Header />
            <div className="contact">
                <div className="contact__content">
                    <h1 className="h2">Zapraszam do kontaktu lub umówienia terminu spotkania</h1>
                    <div className="contact__data">
                        <div className="contact__name">
                            <p>Kancelaria Adwokacka</p>
                            <p>Katarzyna Namyślak</p>
                        </div>
                        <div className="contact__contact">
                            <p>
                                <a href="tel:+48695180846">+48 695-180-846</a>
                            </p>
                            <p>
                                <a href="mailto:kontakt@adwokatnamyslak.pl">kontakt@adwokatnamyslak.pl</a>
                            </p>
                        </div>
                        <div className="contact__address">
                            <p>ul. Kościuszki 10</p>
                            <p>46-300 Olesno</p>
                            <p className="contact__address__link">
                                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4710f3cb3779a08f:0xc3a89e874cc1c742?source=g.page.m._" target="_blank">
                                    Pokaż na mapie
                                </a>
                            </p>
                        </div>
                        <div className="contact__details">
                            <p>NIP: 5742025831</p>
                            <p>REGON:520417111</p>
                            <p>ING Bank Śląski: 38 1050 1171 1000 0092 9148 3486</p>
                        </div>
                    </div>
                </div>
                <div className="contact__img" style={{ maxWidth: "650px" }}>
                    <ImgWithFallback src={img5Webp} fallbackSrc={img5} style={{ width: "100%", borderBottomLeftRadius: "70%" }} role="presentation" />
                </div>
            </div>
            <div className="contact__map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.5308093398744!2d18.421871130727045!3d50.876885357418686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710f3cb3779a08f%3A0xc3a89e874cc1c742!2sKancelaria%20Adwokacka%20Adwokat%20Katarzyna%20Namy%C5%9Blak!5e0!3m2!1spl!2spl!4v1655117863620!5m2!1spl!2spl"
                    width="100%"
                    height="650"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <Footer />
        </>
    );
}
