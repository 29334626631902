import React from "react";

export default function Close() {
    return (
        <div>
            <svg width="30" height="24" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2H30V0H0V2Z" fill="currentColor" />
                <path d="M0 11H30V9H0V11Z" fill="currentColor" />
                <path d="M0 20H30V18H0V20Z" fill="currentColor" />
            </svg>
        </div>
    );
}
