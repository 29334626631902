import React from "react";

export default function Building() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 35.2202H34.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.5 24.7202H34.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 24.7202V35.2202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M33 24.7202V35.2202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 14.2202H30" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 14.2202V24.7202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 17.9702V21.7202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 17.9702V21.7202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 27.7202V31.4702" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 27.7202V31.4702" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 27.7202V31.4702" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 27.7202V31.4702" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 14.2202V24.7202" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M9 14.2202C9 11.8333 9.94821 9.54408 11.636 7.85625C13.3239 6.16843 15.6131 5.22021 18 5.22021C20.3869 5.22021 22.6761 6.16843 24.364 7.85625C26.0518 9.54408 27 11.8333 27 14.2202H9Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M18 5.22028V0.781738" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
}
