import React from "react";

export default function Bank() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75586 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.25586 29.1495H9.75587" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0.755859 35.1495H35.2559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0.755859 32.1495H35.2559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.25586 17.1495H9.75587" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.2559 29.1495H21.7559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.2559 17.1495H21.7559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.25586 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.7559 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.2559 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26.2559 29.1495H33.7559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26.2559 17.1495H33.7559" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27.7559 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.2559 29.1495V17.1495" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M2.47572 12.8069C2.35215 12.9028 2.26166 13.035 2.2169 13.1848C2.17215 13.3347 2.17537 13.4948 2.22612 13.6428C2.27687 13.7907 2.37261 13.9191 2.49993 14.01C2.62726 14.1008 2.7798 14.1496 2.93621 14.1494H33.0861C33.2424 14.1498 33.3949 14.1013 33.5223 14.0108C33.6497 13.9202 33.7456 13.7922 33.7967 13.6444C33.8477 13.4967 33.8513 13.3367 33.807 13.1869C33.7627 13.037 33.6727 12.9047 33.5496 12.8084L18.5151 1.011C18.3832 0.907846 18.2206 0.851807 18.0531 0.851807C17.8857 0.851807 17.723 0.907846 17.5911 1.011L2.47572 12.8069Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
