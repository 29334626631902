import React from "react";

export default function Police() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.21812 24.3585L8.01913 18.4575C8.09635 18.3368 8.1374 18.1965 8.1374 18.0532C8.1374 17.91 8.09635 17.7697 8.01913 17.649L4.20312 11.649"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.25 9.75H11.6625C11.7685 9.75003 11.8733 9.72759 11.97 9.68415C12.0667 9.64071 12.1531 9.57727 12.2235 9.498L16.5 4.6815"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.25 26.25H11.6625C11.7685 26.25 11.8733 26.2724 11.97 26.3159C12.0667 26.3593 12.1531 26.4227 12.2235 26.502L16.5 31.3185"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 12C4.24264 12 5.25 10.9926 5.25 9.75C5.25 8.50736 4.24264 7.5 3 7.5C1.75736 7.5 0.75 8.50736 0.75 9.75C0.75 10.9926 1.75736 12 3 12Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 5.25C19.2426 5.25 20.25 4.24264 20.25 3C20.25 1.75736 19.2426 0.75 18 0.75C16.7574 0.75 15.75 1.75736 15.75 3C15.75 4.24264 16.7574 5.25 18 5.25Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 35.25C19.2426 35.25 20.25 34.2426 20.25 33C20.25 31.7574 19.2426 30.75 18 30.75C16.7574 30.75 15.75 31.7574 15.75 33C15.75 34.2426 16.7574 35.25 18 35.25Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 28.5C4.24264 28.5 5.25 27.4926 5.25 26.25C5.25 25.0074 4.24264 24 3 24C1.75736 24 0.75 25.0074 0.75 26.25C0.75 27.4926 1.75736 28.5 3 28.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.7806 24.3585L27.9796 18.4575C27.9024 18.3368 27.8613 18.1965 27.8613 18.0532C27.8613 17.91 27.9024 17.7697 27.9796 17.649L31.7956 11.649"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.75 9.75H24.3375C24.2315 9.75003 24.1267 9.72759 24.03 9.68415C23.9333 9.64071 23.8469 9.57727 23.7765 9.498L19.5 4.6815"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.75 26.25H24.3375C24.2315 26.25 24.1267 26.2724 24.03 26.3159C23.9333 26.3593 23.8469 26.4227 23.7765 26.502L19.5 31.3185"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33 12C34.2426 12 35.25 10.9926 35.25 9.75C35.25 8.50736 34.2426 7.5 33 7.5C31.7574 7.5 30.75 8.50736 30.75 9.75C30.75 10.9926 31.7574 12 33 12Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33 28.5C34.2426 28.5 35.25 27.4926 35.25 26.25C35.25 25.0074 34.2426 24 33 24C31.7574 24 30.75 25.0074 30.75 26.25C30.75 27.4926 31.7574 28.5 33 28.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 24.75C21.7279 24.75 24.75 21.7279 24.75 18C24.75 14.2721 21.7279 11.25 18 11.25C14.2721 11.25 11.25 14.2721 11.25 18C11.25 21.7279 14.2721 24.75 18 24.75Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
