import React from "react";
import "./nav.scss";
import NavigationLink from "../layout/NavigationLink";

export default function Nav() {
    const links = [
        { name: "O mnie", url: "/o-mnie" },
        { name: "Zakres usług", url: "/zakres-uslug" },
        { name: "Kontakt", url: "/kontakt" },
    ];
    return (
        <nav className="siteNav">
            <ul>
                {links.map((link) => (
                    <NavigationLink name={link.name} url={link.url} key={link.name} />
                ))}
            </ul>
        </nav>
    );
}
