import React from 'react'

export default function 
() {
  return (
    <div>
        <svg width="28" height="9" viewBox="0 0 28 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.3536 4.85355C27.5488 4.65829 27.5488 4.34171 27.3536 4.14645L24.1716 0.964466C23.9763 0.769204 23.6597 0.769204 23.4645 0.964466C23.2692 1.15973 23.2692 1.47631 23.4645 1.67157L26.2929 4.5L23.4645 7.32843C23.2692 7.52369 23.2692 7.84027 23.4645 8.03553C23.6597 8.2308 23.9763 8.2308 24.1716 8.03553L27.3536 4.85355ZM0 5H27V4H0V5Z" fill="currentColor"/>
        </svg>
    </div>
  )
}
