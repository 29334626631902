import React from "react";

export default function AdvocacySmall() {
    return (
        <div>
            <svg width="41" height="37" viewBox="0 0 41 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5669 30.7135H13.357V15.8387C13.3427 14.3713 13.1001 13.3068 12.8147 12.6306C12.5293 11.8538 12.0583 11.2208 11.2734 10.703C12.9003 11.0194 13.9278 11.6956 14.4986 12.5299C15.0837 13.3787 15.4548 14.918 15.4548 17.1334V25.7936C15.4548 27.2609 15.6546 28.3254 16.0542 28.9584C16.3253 29.4044 16.839 29.7208 17.5669 29.9654C17.5669 26.6423 17.5669 22.8157 17.5383 19.2625C17.524 14.2562 17.838 9.68158 16.3538 8.04161C15.5689 7.1497 14.3987 6.93391 14.0562 6.86198V7.86898C12.7576 6.70374 13.8707 4.80483 15.5261 4.99184C17.524 3.2224 20.9062 4.21502 22.3618 6.20024C23.5035 7.39425 23.846 10.0125 23.846 13.0047V29.9798C24.5595 29.7352 25.0733 29.4187 25.3444 28.9728C25.744 28.3398 25.9438 27.2897 25.9438 25.8079V17.1909C25.9438 14.9467 26.2292 13.4075 26.8143 12.5443C27.3566 11.7531 28.4269 11.1777 29.9396 10.8468V10.9475C29.2546 11.4222 28.7694 11.9833 28.484 12.645C28.1986 13.3355 28.0559 14.4145 28.0559 15.8818V30.7566H23.846V37.0288H21.7339C21.7339 32.9576 21.7339 11.4942 21.7197 11.4942C21.577 8.28617 21.0061 6.41603 18.7513 5.79744C17.9094 5.58166 16.9389 5.65359 16.1683 6.05639C19.0082 6.81883 19.6789 9.33632 19.6789 14.4001L19.6504 37.0432H17.5811C17.5811 35.5183 17.5811 33.3029 17.5954 30.7566L17.5669 30.7135Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.2494 22.3266C36.2901 22.3266 37.917 21.6936 39.13 20.442C40.3716 19.2192 41.0138 16.86 41.0138 15.1337C41.0138 13.6232 40.5143 12.2422 39.4725 11.0626C38.4736 9.83978 37.2177 9.30751 35.7193 9.23558C35.6194 9.22119 35.5338 9.22119 35.4339 9.22119C31.9518 9.24996 30.2108 11.2208 30.1965 15.1769L30.168 30.7566L35.7907 30.7853C35.7907 30.7853 35.7336 30.1955 35.705 30.1955C32.9936 29.8647 32.4228 28.4405 32.3657 26.3833V22.0532H32.3514C32.9793 22.2402 33.65 22.3409 34.278 22.3409L34.2494 22.3266ZM32.2943 15.5941C32.2943 14.1986 32.5655 13.1053 33.122 12.2997C34.2922 10.7029 36.4043 10.8612 37.6173 12.2422C38.3309 13.019 38.6734 13.9541 38.6734 15.0762C38.6734 16.2846 38.2452 18.2266 37.4175 19.1761C36.5898 20.0968 35.4196 20.5571 33.9355 20.5571C33.4931 20.5571 32.9508 20.514 32.3229 20.442V15.5941H32.2943Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.84781 17.9102V14.4289C8.53385 11.897 6.8499 10.9187 3.88157 11.2784C1.82658 11.5661 -0.11425 13.6664 -8.34409e-05 13.2348C0.42804 11.7531 0.585019 11.1058 1.09877 10.4872C1.99783 9.40825 3.81022 9.26439 4.58084 9.22123C8.33406 9.06299 11.0027 10.89 11.2453 14.9755V30.7278H8.86208V29.951C8.07719 30.4833 7.02115 30.7566 5.73678 30.7566C5.63688 30.771 5.56553 30.7566 5.45136 30.7422C3.95293 30.6559 2.68283 30.138 1.69814 28.9296C0.670644 27.7356 0.171166 26.3834 0.171166 24.8585C0.171166 23.1034 0.78481 20.7873 2.04064 19.5214C3.25366 18.2842 4.88053 17.68 6.92125 17.68C7.54917 17.68 8.20562 17.7376 8.84781 17.9246V17.9102ZM8.86208 19.5358C8.24844 19.4495 7.69188 19.4063 7.24948 19.4063C5.76532 19.4063 4.59511 19.8666 3.76741 20.8017C2.26897 22.4992 1.98356 25.9662 3.56762 27.7356C4.76636 29.0879 6.89271 29.2605 8.06292 27.6781C8.61948 26.9013 8.89062 25.8367 8.89062 24.4845V19.5358H8.86208Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2635 3.9417C21.292 3.79784 21.3206 3.66837 21.3776 3.49574C21.5774 2.61821 21.4347 0.805617 20.6641 0.0144043C20.9781 0.906317 20.9495 2.01402 20.6926 3.04979C20.607 3.35188 20.5214 3.6396 20.4072 3.92731C20.6641 4.01363 20.9067 4.12871 21.135 4.27257C21.3919 4.43081 21.6202 4.57467 21.8343 4.7473C21.8343 4.71853 21.8486 4.68975 21.8486 4.68975C21.8914 4.48835 21.9485 4.27257 22.0056 4.07117C22.3481 3.04979 22.9474 2.2298 23.6895 1.66876C22.6477 1.88454 21.7059 2.70453 21.2635 3.9417V3.9417Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5804 2.71891C18.5519 2.81961 18.5376 2.90593 18.5234 2.97786C18.2522 2.0284 17.6528 1.26596 16.8965 0.834389C17.4388 1.58244 17.7955 2.48874 17.8954 3.49574C18.1095 3.48136 18.3378 3.48136 18.5519 3.49574C18.766 3.51013 19.0086 3.52451 19.2369 3.58206C19.2654 3.29434 19.3225 2.99224 19.3796 2.70453C19.6365 1.65437 19.979 0.647375 20.6782 0.0144043C19.6222 0.33089 18.7802 1.94209 18.5947 2.7333L18.5804 2.71891Z"
                    fill="black"
                />
            </svg>
        </div>
    );
}
