import React from "react";

export default function Contract() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.25 33L33.75 30.099V23.25C33.813 20.583 30.6285 18.2055 27.75 15.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M30.029 26.25L26.2025 22.3395C26.0318 22.1619 25.8277 22.0198 25.602 21.9214C25.3762 21.823 25.1332 21.7702 24.8869 21.7661C24.6407 21.762 24.396 21.8067 24.1671 21.8975C23.9382 21.9883 23.7295 22.1235 23.553 22.2953C23.3765 22.4672 23.2358 22.6722 23.1389 22.8986C23.042 23.125 22.9908 23.3684 22.9884 23.6147C22.9859 23.8609 23.0322 24.1053 23.1245 24.3336C23.2169 24.5619 23.3535 24.7697 23.5265 24.945L27.7505 29.25V31.5C27.9802 32.8419 28.4914 34.1199 29.2505 35.25"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M0.75 3L2.25 5.901V12.75C2.187 15.417 5.3715 17.7945 8.25 20.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M5.97266 9.75L9.79916 13.6605C9.96979 13.8381 10.1739 13.9802 10.3996 14.0786C10.6254 14.177 10.8684 14.2298 11.1147 14.2339C11.3609 14.238 11.6056 14.1934 11.8345 14.1025C12.0634 14.0117 12.2721 13.8765 12.4486 13.7047C12.6251 13.5329 12.7658 13.3279 12.8627 13.1014C12.9596 12.875 13.0108 12.6316 13.0132 12.3854C13.0157 12.1391 12.9694 11.8948 12.8771 11.6664C12.7847 11.4381 12.6481 11.2303 12.4752 11.055L8.25116 6.75V4.5C8.02146 3.15813 7.51025 1.88011 6.75116 0.75"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.25 12.0779V34.4999C8.25 34.6988 8.32902 34.8896 8.46967 35.0302C8.61032 35.1709 8.80109 35.2499 9 35.2499H25.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M16.5 3.75H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27.75 23.922V15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.5 23.25H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M24.75 18.75H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 27.75H11.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M30.75 6.75C30.75 7.93669 30.3981 9.09673 29.7388 10.0834C29.0795 11.0701 28.1425 11.8392 27.0461 12.2933C25.9497 12.7474 24.7433 12.8662 23.5795 12.6347C22.4156 12.4032 21.3465 11.8318 20.5074 10.9926C19.6682 10.1535 19.0968 9.08443 18.8653 7.92054C18.6338 6.75666 18.7526 5.55026 19.2067 4.4539C19.6609 3.35754 20.4299 2.42047 21.4166 1.76118C22.4033 1.10189 23.5633 0.75 24.75 0.75"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.75 2.25L25.281 7.719C25.2113 7.78884 25.1286 7.84426 25.0374 7.88207C24.9463 7.91988 24.8487 7.93934 24.75 7.93934C24.6513 7.93934 24.5537 7.91988 24.4626 7.88207C24.3714 7.84426 24.2887 7.78884 24.219 7.719L22.5 6"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
