import React from "react";
import NavigationLink from "../layout/NavigationLink";
import NavigationFooterLink from "../layout/NavigationFooterLink";

export default function FooterNav() {
    const links = [
        { name: "Strona główna", url: "/" },
        { name: "O mnie", url: "/o-mnie" },
        { name: "Zakres usług", url: "/zakres-uslug" },
        { name: "Kontakt", url: "/kontakt" },
    ];

    function handleClickFooter() {
        window.scroll({
            top: 0,
            left: 0,
        });
    }

    return (
        <nav>
            <ul>
                {links.map((link) => (
                    <NavigationFooterLink name={link.name} url={link.url} key={link.name} handleClickFooter={handleClickFooter} />
                ))}
            </ul>
        </nav>
    );
}
