import React from 'react'

export default function Advocacy() {
  return (
    <div>
        <svg width="74" height="67" viewBox="0 0 74 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M31.7065 55.6162H24.1082V28.6807C24.0824 26.0236 23.6446 24.0959 23.1294 22.8716C22.6143 21.4649 21.7643 20.3187 20.3477 19.3809C23.284 19.954 25.1385 21.1784 26.1687 22.6893C27.2248 24.2262 27.8945 27.0135 27.8945 31.0252V46.7072C27.8945 49.3642 28.2551 51.2919 28.9763 52.4381C29.4657 53.2456 30.3929 53.8187 31.7065 54.2616C31.7065 48.2441 31.7065 41.3148 31.655 34.8806C31.6292 25.8152 32.1959 17.5314 29.5172 14.5617C28.1005 12.9466 25.9885 12.5559 25.3703 12.4257V14.2491C23.0264 12.1391 25.0354 8.70054 28.0233 9.03918C31.6292 5.83506 37.7337 7.63249 40.3609 11.2274C42.4214 13.3895 43.0396 18.1306 43.0396 23.5489V54.2876C44.3275 53.8448 45.2547 53.2717 45.7441 52.4641C46.4653 51.318 46.8259 49.4163 46.8259 46.7332V31.1294C46.8259 27.0656 47.341 24.2783 48.3971 22.7153C49.3759 21.2826 51.3076 20.2406 54.0379 19.6414V19.8238C52.8015 20.6834 51.9258 21.6994 51.4107 22.8977C50.8955 24.148 50.6379 26.1018 50.6379 28.7589V55.6943H43.0396V67.052H39.2276C39.2276 59.6799 39.2276 20.8137 39.2018 20.8137C38.9442 15.0046 37.914 11.6181 33.8444 10.498C32.3247 10.1072 30.5732 10.2375 29.1823 10.9669C34.308 12.3475 35.5186 16.9062 35.5186 26.0757L35.467 67.0781H31.7323C31.7323 64.3168 31.7323 60.3051 31.758 55.6943L31.7065 55.6162Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M61.8167 40.4292C65.5 40.4292 68.4363 39.283 70.6256 37.0167C72.8665 34.8025 74.0256 30.5303 74.0256 27.4043C74.0256 24.6691 73.1241 22.1683 71.2438 20.0322C69.4408 17.818 67.1742 16.8542 64.4697 16.7239C64.2894 16.6979 64.1348 16.6979 63.9545 16.6979C57.6698 16.75 54.5275 20.3188 54.5017 27.4825L54.4502 55.6944L64.5985 55.7465C64.5985 55.7465 64.4954 54.6784 64.4439 54.6784C59.5501 54.0793 58.5198 51.5004 58.4168 47.7753V39.9343H58.391C59.5243 40.2729 60.7349 40.4553 61.8682 40.4553L61.8167 40.4292ZM58.288 28.2379C58.288 25.7111 58.7774 23.7313 59.7819 22.2725C61.894 19.381 65.706 19.6676 67.8954 22.1683C69.1832 23.575 69.8014 25.2682 69.8014 27.3001C69.8014 29.4883 69.0287 33.005 67.5348 34.7243C66.0409 36.3915 63.9288 37.2251 61.2501 37.2251C60.4516 37.2251 59.4728 37.1469 58.3395 37.0167V28.2379H58.288Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9692 32.4319V26.1279C15.4026 21.5431 12.3633 19.7718 7.00579 20.423C3.29678 20.944 -0.206183 24.7473 -0.000126781 23.9658C0.772585 21.2826 1.05591 20.1104 1.98317 18.9903C3.60586 17.0365 6.87701 16.776 8.26789 16.6979C15.042 16.4113 19.8586 19.7197 20.2964 27.1178V55.6423H15.995V54.2356C14.5784 55.1994 12.6723 55.6944 10.3542 55.6944C10.1739 55.7204 10.0451 55.6944 9.83907 55.6683C7.13458 55.512 4.8422 54.5742 3.06496 52.3861C1.21045 50.2239 0.308958 47.7753 0.308958 45.014C0.308958 41.8359 1.41651 37.6419 3.68313 35.3495C5.87248 33.1092 8.80878 32.0151 12.492 32.0151C13.6254 32.0151 14.8102 32.1193 15.9692 32.458V32.4319ZM15.995 35.3756C14.8874 35.2193 13.8829 35.1411 13.0845 35.1411C10.4057 35.1411 8.29364 35.9747 6.79973 37.6679C4.09524 40.7418 3.5801 47.0198 6.43914 50.2239C8.60273 52.6726 12.4405 52.9852 14.5526 50.1197C15.5571 48.713 16.0465 46.7854 16.0465 44.3367V35.3756H15.995Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M38.3775 7.13759C38.429 6.87709 38.4805 6.64264 38.5835 6.33005C38.9441 4.74101 38.6865 1.45874 37.2957 0.026001C37.8623 1.64109 37.8108 3.64692 37.3472 5.5225C37.1926 6.06955 37.0381 6.59054 36.832 7.11154C37.2957 7.26784 37.7335 7.47623 38.1456 7.73673C38.6093 8.02328 39.0214 8.28378 39.4077 8.59637C39.4077 8.54428 39.4335 8.49218 39.4335 8.49218C39.5108 8.12748 39.6138 7.73673 39.7168 7.37204C40.335 5.5225 41.4168 4.03767 42.7561 3.02172C40.8759 3.41247 39.1759 4.89731 38.3775 7.13759V7.13759Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.5354 4.92336C33.4839 5.10571 33.4582 5.262 33.4324 5.39225C32.943 3.67297 31.8612 2.29233 30.4961 1.51084C31.4749 2.86543 32.1188 4.50656 32.2991 6.33004C32.6854 6.30399 33.0976 6.30399 33.4839 6.33004C33.8703 6.35609 34.3081 6.38214 34.7202 6.48634C34.7718 5.96535 34.8748 5.4183 34.9778 4.89731C35.4414 2.99567 36.0596 1.17219 37.3217 0.026001C35.4157 0.599096 33.896 3.51667 33.5612 4.94941L33.5354 4.92336Z" fill="black"/>
        </svg>
    </div>
  )
}
