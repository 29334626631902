import React from "react";
import Logo from "./Logo";
import LogoLight from "./LogoLight";
import Nav from "./Nav";
import MobileNav from "./MobileNav";
import HeaderContact from "./HeaderContact";
import { Routes, Route, NavLink } from "react-router-dom";
import "./header.scss";

export default function Header({ lightHeader }) {
    return (
        <header className={lightHeader ? "lightheader" : ""}>
            <Nav />
            <MobileNav />
            <NavLink to="/">{lightHeader ? <LogoLight /> : <Logo />}</NavLink>
            <HeaderContact />
        </header>
    );
}
