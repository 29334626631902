import React from "react";
import BackgroundWrapper from "./BackgroundWrapper";
import "./singleservice.scss";

export default function SingleService({ id, name, text1, text2, elements, url, extra, icon }) {
    return (
        <BackgroundWrapper url={url} className="singleService" id={id}>
            <div className="singleService__icon">{icon}</div>
            <h2>{name}</h2>
            <div className="service__top">
                <div>
                    <p>{text1}</p>
                    <p>{text2}</p>
                </div>
                <div>
                    <ul>
                        {elements.map((element) => (
                            <li key={element}>{element}</li>
                        ))}
                    </ul>
                    <p className="service__extra">
                        <span>{extra}</span>
                    </p>
                </div>
            </div>
        </BackgroundWrapper>
    );
}
