import React from "react";
import FooterNav from "./FooterNav";
import "./footer.scss";
import img123 from "../../img/img1.jpg";
import MiniLogo from "../header/MiniLogo";

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <div className="footer__content">
                    <div className="footer__contact">
                        <p>
                            <a href="tel:+48695180846">+48 695-180-846</a>
                        </p>
                        <p>
                            <a href="mailto:kontakt@adwokatnamyslak.pl">kontakt@adwokatnamyslak.pl</a>
                        </p>
                    </div>
                    <div className="footer__address">
                        <p>ul. Kościuszki 10</p>
                        <p>46-300 Olesno</p>
                        <p className="footer__address__link">
                            <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4710f3cb3779a08f:0xc3a89e874cc1c742?source=g.page.m._" target="_blank">
                                Pokaż na mapie
                            </a>
                        </p>
                    </div>
                    <div className="footerBig__bottom">
                        <div className="footer__nav">
                            <FooterNav />
                        </div>
                        <p className="footer__copyright">Copyright @ 2022 by Kancelaria Adwokacka Katarzyna Namyślak. All rights reserved</p>
                    </div>
                </div>

                <div className="footer__mark">
                    <MiniLogo />
                </div>
            </div>
        </footer>
    );
}
