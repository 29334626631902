import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./styles.css";

const breakpoint = 900;
const desktopWidth = 1800;
const mobileWidth = 375;
const onResize = () => {
    const html = document.documentElement;
    const windowWidth = window.innerWidth;
    const base = windowWidth < breakpoint ? mobileWidth : desktopWidth;
    let size = (100 * windowWidth) / base / 100;
    if (windowWidth >= breakpoint) size = (90 * size) / 100;
    html.style.fontSize = size + "px";
};

const changeVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};
changeVh();

window.addEventListener("resize", changeVh);

window.addEventListener("resize", onResize);
onResize();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
