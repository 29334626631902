import React from "react";
import "./services.scss";
import img6 from "../img/img6.jpg";
import img6Webp from "../img/img6.webp";
import Footer from "../components/footer/Footer";
import bg1 from "../img/bg1.webp";
import bg2 from "../img/bg2.webp";
import bg3 from "../img/bg3.webp";
import bg4 from "../img/bg4.webp";
import bg5 from "../img/bg5.webp";
import Police from "../components/icons/Police";
import Family from "../components/icons/Family";
import Bank from "../components/icons/Bank";
import Contract from "../components/icons/Contract";
import Building from "../components/icons/Building";
import Header from "../components/header/Header";
import SingleService from "../components/layout/SingleService";
import SideIcons from "../components/layout/SideIcons";
import ImgWithFallback from "../components/layout/ImgWithFallback";

export default function Services() {
    const services = [
        {
            name: "Prawo Karne",
            text1: "W ramach realizacji usług z zakresu prawa karnego wykonuję czynności zarówno, jako obrońca, jak i pełnomocnik pokrzywdzonych na etapie postępowania przygotowawczego, a także sądowego.",
            text2: "Udzielam także pomocy na etapie postępowania wykonawczego dla osób prawomocnie skazanych.",
            elements: [
                "obrona podejrzanego na etapie postępowania przygotowawczego,",
                "obrona oskarżonego na etapie postępowania sądowego,",
                "obrony obwinionego w postępowaniu w sprawach o wykroczenia,",
                "reprezentacja pokrzywdzonego na etapie postępowania przygotowawczego,",
                "reprezentacja oskarżyciela posiłkowego na etapie postępowania sądowego,",
                "obrony skazanego w postępowaniu wykonawczym.",
            ],
            url: bg1,
            id: "criminal",
            icon: <Police />,
        },
        {
            name: "Prawo Rodzinne",
            text1: "W ramach realizacji usług z zakresu prawa rodzinnego wykonuję czynności związane z udzieleniem porad prawnych, sporządzaniem wszelkiego rodzaju pism w sprawie, a także reprezentacją Klienta zarówno przed Sądem, jak i Organami Egzekucyjnymi.",
            text2: "Biorę także udział w negocjacjach i mediacji pomiędzy stronami.",
            elements: [
                "rozwód i separację,",
                "ustanowienie rozdzielności majątkowej,",
                "alimenty (na małoletniego/ na małżonka),",
                "uchylenie obowiązku alimentacyjnego,",
                "ustalenie kontaktów z małoletnim,",
                "władzę rodzicielską (pozbawienie lub ograniczenie władzy rodzicielskiej)",
                "ustalenie miejsca pobytu małoletniego,",
                "ustalenie/zaprzeczenie ojcostwa,",
                "ubezwłasnowolnienie,",
                "wyrażenie zgodny  na dokonanie czynności przekraczającej zwykły zarząd majątkiem dziecka,",
                "zmiana nazwiska.",
            ],
            url: bg2,
            id: "family",
            icon: <Family />,
        },
        {
            name: "Prawo Cywilne",
            text1: "W ramach realizacji usług z zakresu prawa cywilnego wykonuję czynności związane z udzieleniem porad prawnych, sporządzaniem opinii prawnych, umów, wszelkiego rodzaju pism w sprawie, a także reprezentacją Klienta zarówno przed Sądem, jak i Organami Egzekucyjnymi.",
            text2: "Biorę także udział w negocjacjach i mediacji pomiędzy stronami.",
            elements: [
                "stwierdzenie nabycia spadku,",
                "dział spadku,",
                "zachowek,",
                "zniesienie współwłasności,",
                "zasiedzenie,",
                "zaniechanie naruszeń, wydanie rzeczy,",
                "wynagrodzenie za korzystanie z rzeczy,",
                "zapłatę,",
                "ustanowienie służebności,",
                "roszczenia wynikające z zobowiązań,",
                "upadłość konsumencką,",
                "ochronę dóbr osobistych.",
            ],
            url: bg3,
            id: "civil",
            icon: <Bank />,
        },
        {
            name: "Prawo Gospodarcze",
            text1: "W ramach realizacji usług z zakresu prawa gospodarczego wykonuję czynności związane z udzieleniem porad prawnych, sporządzaniem opinii prawnych, umów, wszelkiego rodzaju pism w sprawie, a także reprezentacją Klienta zarówno przed Sądem, jak i Organami Egzekucyjnymi.",
            text2: "Biorę także udział w negocjacjach i mediacji pomiędzy stronami.",
            elements: [
                "redagowania projektów umów,",
                "opiniowania i analizy dokumentów prawnych,",
                "sporządzania pozwu o zapłatę,",
                "windykacji należności,",
                "zakładania działalności gospodarczej,",
            ],
            extra: "Kancelaria zapewnia również możliwość podjęcia stałą obsługi prawnej.",
            url: bg4,
            id: "economic",
            icon: <Contract />,
        },
        {
            name: "Prawo Administracyjne",
            text1: "W ramach realizacji usług z zakresu prawa administracyjnego wykonuję czynności związane z udzieleniem porad prawnych, sporządzaniem wszelkiego rodzaju pism w sprawie, a także reprezentacją Klienta zarówno przed Sądem, organami samorządu terytorialnego, jak i innymi.",
            text2: "Biorę także udział w negocjacjach i mediacji pomiędzy stronami.",
            elements: [
                "odwołania od decyzji administracyjnej,",
                "skargi do Wojewódzkiego Sądu Administracyjnego,",
                "skargi kasacyjnej do Naczelnego Sądu Administracyjnego,",
                "wniosku i skargi do organów administracji publicznej,",
                "wniosku o stwierdzenie nieważności decyzji administracyjnej,",
                "wniosku o wznowienie postępowania administracyjnego.",
            ],
            url: bg5,
            id: "administrative",
            icon: <Building />,
        },
    ];

    return (
        <>
            <SideIcons />
            <Header />
            <div className="services">
                <div className="services__portrait">
                    <img
                        src={img6}
                        style={{
                            width: "100%",
                            borderBottomRightRadius: "70%",
                        }}
                    ></img>
                </div>
                <div className="services__top">
                    <h1 className="h2">W ramach świadczonych usług Kancelaria prowadzi czynności związane z:</h1>
                </div>
                <div className="services__main">
                    <div className="services__main__left">
                        <div className="services__main__left__content">
                            <p className="services__main__title">I. Udzielaniem porad prawnych</p>
                            <ul>
                                <li>porady prawne udzielane są podczas spotkania w Kancelarii, po uprzednim uzgodnieniu dogodnego terminu,</li>
                                <li>w sytuacji szczególnego zagrożenia życia lub zdrowia możliwym jest odbycie porady online.</li>
                            </ul>
                        </div>
                        <div className="services__main__img">
                            <ImgWithFallback
                                src={img6Webp}
                                fallbackSrc={img6}
                                style={{
                                    width: "100%",
                                    borderBottomRightRadius: "70%",
                                }}
                                role="presentation"
                            />
                        </div>
                    </div>
                    <div className="services__main__right">
                        <p className="services__main__title">II. Sporządzaniem</p>
                        <ul>
                            <li>
                                umowy, pozwu, odpowiedzi na pozew, wniosku w postępowaniu nieprocesowym, odpowiedzi na wniosek, wniosku o zawezwanie do próby ugodowej,
                                wniosku o wszczęcie postępowania egzekucyjnego, wniosku o przywrócenie terminy do dokonania czynności, ugody, pisma procesowego,
                            </li>
                            <li>
                                środka odwoławczego, tj. zażalenie, apelacja, kasacja, skarga kasacyjna, skarga na orzeczenie referendarza, skarga o stwierdzenie
                                niezgodności z prawem prawomocnego orzeczenia, skarga na czynności komornika, kasacja, sprzeciw od wyroku nakazowego, zarzuty od wyroku
                                nakazowego, skarga do WSA, skarga do NSA,
                            </li>
                            <li>wniosku o zabezpieczenie,</li>
                            <li>wniosku o wszczęcie postępowania egzekucyjnego, skargi na czynności komornika, pozbawienie tytułu wykonawczego wykonalności; </li>
                            <li>
                                wniosku o dozór elektroniczny, wstrzymanie wykonania kary, odroczenie wykonania kary, przerwa w odbyciu kary, warunkowe przedterminowe
                                zwolnienie, dozór elektroniczny,{" "}
                            </li>
                            <li>odwołania od decyzji, skargi na decyzję, </li>
                            <li>zgłoszenia upadłości konsumenckiej, </li>
                            <li>zgłoszenia wierzytelności.</li>
                        </ul>
                    </div>
                </div>

                {services.map((service) => (
                    <div key={service.name}>
                        <div className="singleService__line"></div>
                        <SingleService
                            id={service.id}
                            name={service.name}
                            text1={service.text1}
                            text2={service.text2}
                            elements={service.elements}
                            url={service.url}
                            key={service.name}
                            extra={service.extra}
                            icon={service.icon}
                        />
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
}
