import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import "./nav.scss";
import { useState } from "react";
import NavigationLink from "../layout/NavigationLink";
import Hamburger from "../icons/Hamburger";
import Close from "../icons/Close";

export default function MobileNav() {
    const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
    const links = [
        { name: "Strona główna", url: "/" },
        { name: "O mnie", url: "/o-mnie" },
        { name: "Zakres usług", url: "/zakres-uslug" },
        { name: "Kontakt", url: "/kontakt" },
    ];

    function handleClick() {
        setOpenHamburgerMenu(!openHamburgerMenu);
    }

    return (
        <nav className="mobileNav">
            <div
                className={`mobileNav-icon ${openHamburgerMenu ? "mobileNav-icon--black" : "mobileNav-icon"}`}
                onClick={() => {
                    setOpenHamburgerMenu(!openHamburgerMenu);
                }}
            >
                {openHamburgerMenu ? <Hamburger /> : <Close />}
            </div>
            {openHamburgerMenu && (
                <div className="mobileNav-opened">
                    <ul>
                        {links.map((link) => (
                            <NavigationLink name={link.name} url={link.url} key={link.name} handleClick={handleClick} />
                        ))}
                    </ul>
                    <div className="mobileNav-contact">
                        <p>
                            <a href="tel:+48695180846">+48 695-180-846</a>
                        </p>
                        <p className="mobileNav-contact-mail">
                            <a href="mailto:kontakt@adwokatnamyslak.pl">kontakt@adwokatnamyslak.pl</a>
                        </p>
                    </div>
                </div>
            )}
        </nav>
    );
}
