import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";

export default function NavigationFooter({ name, url, handleClickFooter }) {
    return (
        <li>
            <NavLink to={url} className={(navData) => (navData.isActive ? "active" : "link")} onClick={handleClickFooter}>
                {name}
            </NavLink>
        </li>
    );
}
