import React from "react";
import Police from "../../components/icons/Police";
import Family from "../../components/icons/Family";
import Bank from "../../components/icons/Bank";
import Contract from "../../components/icons/Contract";
import Building from "../../components/icons/Building";
import { HashLink } from "react-router-hash-link";
import "./sideicons.scss";

export default function SideIcons() {
    return (
        <div className="sideIcons">
            <HashLink to={"#criminal"} scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                <Police />
            </HashLink>
            <HashLink to={"#family"} scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                <Family />
            </HashLink>

            <HashLink to={"#civil"} scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                <Bank />
            </HashLink>

            <HashLink to={"#economic"} scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                <Contract />
            </HashLink>

            <HashLink to={"#administrative"} scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}>
                <Building />
            </HashLink>
        </div>
    );
}
