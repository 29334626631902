import React from "react";
import "./about.scss";
import img from "../img/img3.jpg";
import imgWebp from "../img/img3.webp";
import { Routes, Route, Link } from "react-router-dom";
import Arrow from "../components/icons/Arrow";
import Footer from "../components/footer/Footer";
import Advocacy from "../components/icons/Advocacy";
import AdvocacySmall from "../components/icons/AdvocacySmall";
import Header from "../components/header/Header";
import ImgWithFallback from "../components/layout/ImgWithFallback";

export default function About() {
    return (
        <div>
            <Header />
            <div className="about">
                <div className="about__top">
                    <div>
                        <h1 className="h2">Ukończyłam wyższe studia magisterskie na kierunku „Prawo” na Wydziale Prawa i Administracji Uniwersytetu Opolskiego.</h1>
                    </div>
                    <div className="about__top__description">
                        <h3 className="h5">
                            Następnie odbyłam 3-letnią aplikację adwokacką przy Okręgowej Radzie Adwokackiej w Opolu, zakończoną pozytywnie zdanym egzaminem adwokackim w
                            2021 roku i uzyskaniem uprawnień Adwokata.
                        </h3>
                    </div>
                    <div></div>
                    <div className="about__top-icon">
                        <div className="about__top-icon--mobile">
                            <AdvocacySmall />
                        </div>
                        <div className="about__top-icon--desktop">
                            <Advocacy />
                        </div>
                    </div>
                </div>
                <div className="about__img">
                    <div></div>
                    <div className="about__img__content">
                        <p className="h5">
                            Jednocześnie cały czas starałam się rozwijać swoje zainteresowania i poszerzać kompetencje, w związku z czym w 2017 roku rozpoczęłam studia
                            podyplomowe na Uniwersytecie Wrocławskim Wydział Nauk Społecznych uzyskując tytuł zawodowy Mediatora.
                        </p>
                    </div>
                </div>
                <div className="about__bottom">
                    <div>
                        <h3>
                            Doświadczenie zawodowe zdobyłam podczas pracy w renomowanych Kancelariach Adwokackich w Opolu i Oleśnie. Odbyłam także liczne praktyki
                            zawodowe w Sądach i Prokuraturze.
                        </h3>
                    </div>
                    <div className="about__bottom__content">
                        <p className="h5">
                            W ramach praktyki adwokackiej świadczyłam obsługę prawną na rzecz klientów indywidualnych, jak i podmiotów gospodarczych. Do zakresu moich
                            obowiązków należała kompleksowa obsługa poczynając od udzielania porad prawnych, przez sporządzanie pism procesowych, a także reprezentacja
                            Klienta w procesach sądowych.{" "}
                        </p>
                    </div>
                </div>
                <div className="about__portrait">
                    <div style={{ maxWidth: "650px" }}>
                        <ImgWithFallback src={imgWebp} fallbackSrc={img} style={{ width: "100%", borderBottomRightRadius: "80%" }} role="presentation" />
                    </div>
                    <div className="about__portrait__text">
                        <p className="h5">
                            Cechuje mnie sumienność, staranność, dyspozycyjność, zorganizowanie, a przede wszystkim globalne podejście do problemu rozważając materię
                            wielu dziedzin prawa.
                        </p>
                        <p className="h5">Dochowuję także najwyższych standardów bezpieczeństwa i poufności prowadzonych spraw.</p>
                        <Link
                            to="/zakres-uslug"
                            onClick={() => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <span>Zakres usług</span>
                            <Arrow />
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
