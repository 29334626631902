import React from "react";

export default function VerticalArrow() {
    return (
        <>
            <svg width="8" height="68" viewBox="0 0 8 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.64644 67.3536C3.84171 67.5488 4.15829 67.5488 4.35355 67.3536L7.53553 64.1716C7.73079 63.9763 7.73079 63.6597 7.53553 63.4645C7.34027 63.2692 7.02369 63.2692 6.82842 63.4645L4 66.2929L1.17157 63.4645C0.976308 63.2692 0.659726 63.2692 0.464463 63.4645C0.269201 63.6597 0.269201 63.9763 0.464463 64.1716L3.64644 67.3536ZM3.5 -2.18557e-08L3.5 67L4.5 67L4.5 2.18557e-08L3.5 -2.18557e-08Z"
                    fill="white"
                />
            </svg>
        </>
    );
}
