import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import "./index.css";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/o-mnie" element={<About />} />
                <Route path="/zakres-uslug" element={<Services />} />
                <Route path="/kontakt" element={<Contact />} />
            </Routes>
        </div>
    );
}

export default App;
