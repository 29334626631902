import React from "react";

export default function Family() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 2.25V0.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 16.5V22.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M9 28.5C11.4853 28.5 13.5 26.4853 13.5 24C13.5 21.5147 11.4853 19.5 9 19.5C6.51472 19.5 4.5 21.5147 4.5 24C4.5 26.4853 6.51472 28.5 9 28.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.12109 21.7155C6.17615 22.8084 7.539 23.5541 9.0283 23.8534C10.5176 24.1528 12.0627 23.9915 13.4581 23.391"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.4631 28.716C31.8639 27.7326 31.5652 26.5954 31.6036 25.4445V24.1065C31.6036 22.8856 31.1186 21.7146 30.2553 20.8513C29.3919 19.988 28.221 19.503 27.0001 19.503C25.7792 19.503 24.6083 19.988 23.7449 20.8513C22.8816 21.7146 22.3966 22.8856 22.3966 24.1065V25.4445C22.435 26.5954 22.1363 27.7326 21.5371 28.716"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.3965 24.867C23.2702 24.8656 24.1335 24.6764 24.9277 24.3122C25.722 23.9481 26.4287 23.4175 27 22.7565C27.5713 23.4175 28.278 23.9481 29.0723 24.3122C29.8665 24.6764 30.7297 24.8656 31.6035 24.867"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.959 24.84C23.0594 25.8422 23.5286 26.7713 24.2754 27.447C25.0223 28.1228 25.9936 28.4969 27.0007 28.4969C28.0079 28.4969 28.9792 28.1228 29.726 27.447C30.4729 26.7713 30.942 25.8422 31.0425 24.84"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 33C19.864 33 21.375 31.489 21.375 29.625C21.375 27.761 19.864 26.25 18 26.25C16.136 26.25 14.625 27.761 14.625 29.625C14.625 31.489 16.136 33 18 33Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.3709 35.25C20.9433 34.7776 20.4214 34.4 19.839 34.1416C19.2565 33.8832 18.6264 33.7497 17.9892 33.7497C17.352 33.7497 16.7218 33.8832 16.1394 34.1416C15.5569 34.4 15.035 34.7776 14.6074 35.25"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6373 30.477C10.6827 30.1195 9.66523 29.96 8.64701 30.0085C7.62878 30.0569 6.63109 30.3122 5.71475 30.7588C4.79841 31.2054 3.98261 31.8339 3.31709 32.6061C2.65157 33.3782 2.15027 34.2778 1.84375 35.25"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.3633 30.477C25.3179 30.1195 26.3353 29.96 27.3535 30.0085C28.3718 30.0569 29.3694 30.3122 30.2858 30.7588C31.2021 31.2054 32.0179 31.8339 32.6834 32.6061C33.349 33.3782 33.8503 34.2778 34.1568 35.25"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M35.25 16.5C35.25 15.258 33.4035 14.25 31.125 14.25C28.8465 14.25 27 15.258 27 16.5C27 15.258 24.9855 14.25 22.5 14.25C20.0145 14.25 18 15.258 18 16.5C18 15.258 15.9855 14.25 13.5 14.25C11.0145 14.25 9 15.258 9 16.5C9 15.258 7.1535 14.25 4.875 14.25C2.5965 14.25 0.75 15.258 0.75 16.5C0.75 8.6295 8.4735 2.25 18 2.25C27.5265 2.25 35.25 8.6295 35.25 16.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
}
