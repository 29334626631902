import React from "react";
import "./headercontact.scss";

export default function HeaderContact() {
    return (
        <div className="headerContact">
            <p className="headerContact__phone">
                <a href="tel:+48695180846">+48 695-180-846</a>
            </p>
            <p className="headerContact__mail">
                <a href="mailto:kontakt@adwokatnamyslak.pl">kontakt@adwokatnamyslak.pl</a>
            </p>
        </div>
    );
}
