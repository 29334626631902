import React from "react";
import FooterNav from "./FooterNav";
import "./footer.scss";
import footerImg from "../../img/img4.jpg";
import footerImgWebp from "../../img/img4.webp";
import MiniLogo from "../header/MiniLogo";
import ImgWithFallback from "../layout/ImgWithFallback";

export default function BigFooter() {
    return (
        <footer className="footerBig">
            <div className="footerBig__content">
                <h4 className="h3">
                    Wieloletnia praca oraz zdobyte doświadczenie pozwalają na globalne podejście do problemu prawnego rozważając materię wielu dziedzin prawa.
                </h4>
                <div>
                    <div className="footerBig__contact">
                        <p>
                            <a href="tel:+48695180846">+48 695-180-846</a>
                        </p>
                        <p>
                            <a href="mailto:kontakt@adwokatnamyslak.pl">kontakt@adwokatnamyslak.pl</a>
                        </p>
                    </div>
                    <div className="footerBig__address">
                        <p>ul. Kościuszki 10</p>
                        <p>46-300 Olesno</p>
                        <p className="footerBig__address__link">
                            <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4710f3cb3779a08f:0xc3a89e874cc1c742?source=g.page.m._" target="_blank">
                                Pokaż na mapie
                            </a>
                        </p>
                    </div>
                    <div className="footerBig__bottom">
                        <div className="footerBig__nav">
                            <FooterNav />
                        </div>
                        <p className="footerBig__copyright">Copyright @ 2022 by Kancelaria Adwokacka Katarzyna Namyślak. All rights reserved</p>
                        <div className="footerBig__mark">
                            <MiniLogo />
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer__mobile__img">
                <ImgWithFallback
                    src={footerImgWebp}
                    fallbackSrc={footerImg}
                    className={`className="home-content__image-item home-content__image-item--active test"}`}
                    role="presentation"
                />
            </div>
        </footer>
    );
}
