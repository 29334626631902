import React, { useState, useEffect } from "react";
import BigFooter from "../components/footer/BigFooter";
import "./home-desktop.scss";
import "./home-mobile.scss";
import Header from "../components/header/Header";
import ArrowBig from "../components/icons/ArrowBig";
import VerticalArrow from "../components/icons/VerticalArrow";
import Police from "../components/icons/Police";
import Family from "../components/icons/Family";
import Bank from "../components/icons/Bank";
import Contract from "../components/icons/Contract";
import Building from "../components/icons/Building";
import service0 from "../img/service0.png";
import service0Webp from "../img/service0.webp";
import service1 from "../img/service1.png";
import service1Webp from "../img/service1.webp";
import service2 from "../img/service2.png";
import service2Webp from "../img/service2.webp";
import service3 from "../img/service3.png";
import service3Webp from "../img/service3.webp";
import service4 from "../img/service4.png";
import service4Webp from "../img/service4.webp";
import service5 from "../img/service5.png";
import service5Webp from "../img/service5.webp";
import { HashLink } from "react-router-hash-link";
import footerImg from "../img/img4.jpg";
import footerImgWebp from "../img/img4.webp";
import ImgWithFallback from "../components/layout/ImgWithFallback";

export default function Home() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const lightHeader = true;
    const [services, setServices] = useState([
        { url: service0, webp: service0Webp, isActive: true, disabled: true },
        { name: "Karnego", url: service1, webp: service1Webp, isActive: false, icon: <Police />, link: "/zakres-uslug#criminal" },
        { name: "Rodzinnego", url: service2, webp: service2Webp, iscActive: false, icon: <Family />, link: "/zakres-uslug#family" },
        { name: "Cywilnego", url: service3, webp: service3Webp, isActive: false, icon: <Bank />, link: "/zakres-uslug#civil" },
        { name: "Gospodarczego", url: service4, webp: service4Webp, isActive: false, icon: <Contract />, link: "/zakres-uslug#economic" },
        { name: "Administracyjnego", url: service5, webp: service5Webp, isActive: false, icon: <Building />, link: "/zakres-uslug#administrative" },
    ]);

    const handleMouseEnter = (e) => {
        const updated = services.map((service) => (service.name == e.target.innerText ? { ...service, isActive: true } : { ...service, isActive: false }));
        setServices(updated);
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleArrowClick = () => {
        setScrollPosition(window.innerHeight * 0.5);
        window.scrollTo(0, window.innerHeight * 0.5);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="home">
            <div className={`home-hero ${scrollPosition > window.innerHeight * 0.2 ? "home-hero--hidden" : ""}`}>
                <div className="home-hero__wrapper">
                    <Header lightHeader={lightHeader} />
                    <div className="home-hero__content">
                        <h1>Kompleksowa obsługa prawna osób fizycznych i przedsiębiorców</h1>
                        <div onClick={handleArrowClick} className="vertical-arrow">
                            <VerticalArrow />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`home-content ${scrollPosition > window.innerHeight * 0.2 ? "home-content--visible" : ""} ${
                    scrollPosition > window.innerHeight * 0.75 ? "home-content--footer" : "home-content--services"
                }`}
            >
                <div className="home-content__wrapper">
                    <div className="home-services">
                        <div className="home-services__list">
                            <p className="h5 home-services__header">Z zakresu prawa</p>
                            <ul className="home-services__wrapper">
                                {services
                                    .filter((item) => !item.disabled)
                                    .map((service) => (
                                        <li key={service.name} className={`home-services__item ${service.isActive ? "home-services__item--active" : ""}`}>
                                            <span className="home-services__item-icon">{service.icon}</span>
                                            <HashLink to={service.link} scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "start" })}>
                                                <span className="home-services__item-name" onMouseOver={handleMouseEnter}>
                                                    {service.name}
                                                </span>
                                            </HashLink>
                                            <span className="home-services__item-arrow">
                                                <ArrowBig />
                                            </span>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                    <div className="home-footer">
                        <BigFooter />
                    </div>
                </div>
                <div className="home-content__image">
                    <div className="home-content__image-wrapper home-content__image-wrapper--services">
                        {services.map((service) => (
                            <ImgWithFallback
                                key={service.url}
                                src={service.webp}
                                fallbackSrc={service.url}
                                className={`home-content__image-item ${service.isActive ? "home-content__image-item--active" : ""}`}
                                role="presentation"
                            />
                        ))}
                    </div>

                    <div className="home-content__image-wrapper home-content__image-wrapper--footer">
                        <ImgWithFallback
                            src={footerImgWebp}
                            fallbackSrc={footerImg}
                            className="home-content__image-item home-content__image-item--active"
                            role="presentation"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
