import React from "react";

export default function () {
    return (
        <div>
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.68637 2.10049L24.8996 23.3137L26.3138 21.8995L5.10059 0.686279L3.68637 2.10049Z" fill="currentColor" />
                <path d="M26.3136 2.10049L5.10042 23.3137L3.68621 21.8995L24.8994 0.686279L26.3136 2.10049Z" fill="currentColor" />
            </svg>
        </div>
    );
}
