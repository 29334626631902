import React from 'react'
import { Routes, Route, NavLink } from "react-router-dom";

export default function NavigationLink({name, url, handleClick}) {
  return (
    <li>
        <NavLink to={url}
            className={(navData) => (navData.isActive ? 'active' : 'link')} onClick={handleClick}>
            {name}
        </NavLink>
    </li>
  )
}